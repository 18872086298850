import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import BlogTemplateStyles from "./blogTemplate.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faCalendar, faLocationPin } from '@fortawesome/free-solid-svg-icons';

export const query = graphql `
    query($slug: String!) {
        contentfulEvents(slug: {eq:$slug}) {
            eventName
            description {
                raw
            }
            date(formatString:"MMMM DD, YYYY hh:mm A")
            location_short
            location {
                lat
                lon
            }
            price
            eventImage {
                file {
                    url
                    fileName
                }
            }
        }
    }
    `


const Event = (props) => {   

    return (
        <Layout>
            <div className={BlogTemplateStyles.blogPost}>
                <div className={BlogTemplateStyles.image} style={{ backgroundImage: `url(${props.data.contentfulEvents.eventImage.file.url})` }}></div>
                <div className={BlogTemplateStyles.postContainer}>
                    <div>
                        {/* <p className={BlogTemplateStyles.category}>{props.data.contentfulEvents.location}</p> */}
                    </div>
                    <h1 className={BlogTemplateStyles.title}>{props.data.contentfulEvents.eventName}</h1>
                   
                    <div className={BlogTemplateStyles.socialContainer}>
                        <div class="s9-widget-wrapper"></div>
                    </div>
                    <div className={BlogTemplateStyles.description}>
                        <div className={BlogTemplateStyles.summary}>
                            <div className={BlogTemplateStyles.boxSummary}>
                                <div>
                                    <p className={BlogTemplateStyles.dateContainerEvents}><FontAwesomeIcon icon={faCalendar}/> {props.data.contentfulEvents.date}</p>
                                </div>
                                <div>
                                    <p><FontAwesomeIcon icon={faLocationPin} /> {props.data.contentfulEvents.location_short}</p>
                                </div>
                                <div>
                                    <p><FontAwesomeIcon icon={faTag} /> {props.data.contentfulEvents.price}</p>
                                </div>  
                            </div>
                            <div>
                                <button className={BlogTemplateStyles.buy}>Buy Now</button>
                            </div>
                        </div>
                        <div className={BlogTemplateStyles.details}>
                            <div>
                                <p>{renderRichText(props.data.contentfulEvents.description)}</p>
                            </div>
                        </div> 
                    </div>  
                </div>
            </div>
        </Layout>
    )
}

export default Event;